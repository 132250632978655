/* eslint-disable */

import { removeKatexFromHtml } from '@redactor/remove-katex';

(function ($R) {
  $R.add('plugin', 'limiter', {
    translations: {
      en: {
        maxWords: 'max words'
      }
    },
    init(app) {
      this.app = app;
      this.lang = app.lang;
      this.utils = app.utils;
      this.opts = app.opts;
      this.editor = app.editor;
      this.keycodes = app.keycodes;
      this.statusbar = app.statusbar;
    },
    // events
    onpasting(html, e) {
      if (!this.opts.limiter) return;

      const currentHtml = this._getHtml();
      const currentCount = this._count(currentHtml);

      const pastedCount = this._count(html);

      if (currentCount + pastedCount > this.opts.limiter) {
        if (e) e.preventDefault();
        this.opts.input = false;
        return false;
      }

      this.opts.input = true;
    },
    // public
    start() {
      if (!this.opts.limiter) return;
      this._editorElement = this.editor.getElement(); // Cache the editor element
      this._validate();
      this._editorElement.on('paste.redactor-plugin-limiter', this._handlePaste.bind(this));
      this._editorElement.on('keydown.redactor-plugin-limiter', this._limit.bind(this));

      this.statusbar.add('maxWords', `${this.lang.get('maxWords')}: ${this.opts.limiter}`);
    },
    stop() {
      this._editorElement.off('.redactor-plugin-limiter');
      this.opts.input = true;
      this.statusbar.remove('maxWords');
    },

    _handlePaste(e) {
      const clipboardData = e.clipboardData || (e.originalEvent && e.originalEvent.clipboardData) || window.clipboardData;
      const pastedHtml = clipboardData.getData('text/html') || clipboardData.getData('text/plain');

      this.onpasting(pastedHtml, e);
    },

    // private
    _limit(e) {
      const key = e.which;
      const ctrl = e.ctrlKey || e.metaKey;
      const arrows = [37, 38, 39, 40];

      if (key === this.keycodes.ENTER) {
        this._validate(e); // Re-check word count
        if (!this.opts.input) {
          e.preventDefault(); // Block input if limit is exceeded
        }
        return;
      }

      if (key === this.keycodes.BACKSPACE ||
        key === this.keycodes.DELETE ||
        key === this.keycodes.ESC ||
        key === this.keycodes.SHIFT ||
        (ctrl && key === 65) ||
        (ctrl && key === 88) ||
        (ctrl && key === 82) ||
        (ctrl && key === 116) ||
        (arrows.indexOf(key) !== -1)
      ) {
        return;
      }

      this._validate(e);
    },
    _validate(e) {
      const html = this._getHtml();
      const count = this._count(html, e);

      if (count > this.opts.limiter) {
        if (e) e.preventDefault();
        this.opts.input = false;
        return false;
      }

      this.opts.input = true;
    },
    _count(html, e) {
      let count = 0;

      html = this._clean(html);

      if (e) html += (e.key.replace('Enter', ' '));
      if (html !== '') {
        const arrWords = html.split(/\s+/);
        count = (arrWords) ? arrWords.length : 0;
      }
      return count;
    },
    _clean(html) {
      html = removeKatexFromHtml(html);
      html = html.replace(/<\/(.*?)>/gi, ' ');
      html = html.replace(/<(.*?)>/gi, '');
      html = html.replace(/\t/gi, '');
      html = html.replace(/\n/gi, ' ');
      html = html.replace(/\r/gi, ' ');
      html = html.replace(/&nbsp;/g, ' ');
      html = html.replace(/&#xFEFF;/g, '');
      html = html.trim();
      html = this.utils.removeInvisibleChars(html);

      return html;
    },
    _getHtml() {
      return this._editorElement.html();
    }
  });
}(Redactor));
