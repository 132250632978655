import { Application, defaultSchema } from '@hotwired/stimulus';
import { Autocomplete } from 'stimulus-autocomplete';

const customSchema = {
  ...defaultSchema,
  keyMappings: { ...defaultSchema.keyMappings, backspace: 'Backspace' }
};

const application = Application.start(document.documentElement, customSchema);

// Configure Stimulus development experience
// application.debug = false;
// window.Stimulus = application;

application.register('autocomplete', Autocomplete);

export { application };
