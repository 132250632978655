/* global SafeExamBrowser */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { browserExamKeys: Array, configKeys: Array, url: String };

  verify() {
    try {
      if (this.validateBrowserExamKey() && this.validateConfigKey()) this.element.remove();
    } catch (err) {
      window.location = `${this.urlValue}?correct_configuration=false`;
    }
  }

  validateBrowserExamKey() {
    if (this.browserExamKeysValue.length === 0) return true;
    if (this.browserExamKeysValue.includes(SafeExamBrowser.security.browserExamKeys)) return true;

    window.location = `${this.urlValue}?correct_build=false`;
    return false;
  }

  validateConfigKey() {
    if (this.configKeysValue.includes(SafeExamBrowser.security.configKey)) return true;

    window.location = `${this.urlValue}?correct_configuration=false`;
    return false;
  }
}
