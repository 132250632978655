import Dropdown from '@stimulus-components/dropdown';

export default class extends Dropdown {
  toggle() {
    super.toggle();
    this.adjustHeight();
  }

  adjustHeight() {
    const rect = this.menuTarget.getBoundingClientRect();
    const availableHeight = window.innerHeight - rect.top;

    this.menuTarget.style.maxHeight = `${availableHeight - 32}px`; // 32px is the padding of the dropdown
  }
}
