export function debounce(fn, delay, ...args) {
  let timer = null;

  return function () {
    clearTimeout(timer);
    timer = setTimeout(() => { fn.apply(this, args); }, delay);
  };
}

export function throttle(callback, wait, immediate = false) {
  let timeout = null;
  let initialCall = true;

  return function (...args) {
    const callNow = immediate && initialCall;
    const next = () => {
      callback.apply(this, args);
      timeout = null;
    };

    if (callNow) {
      initialCall = false;
      next();
    }

    if (!timeout) {
      timeout = setTimeout(next, wait);
    }
  };
}

export function serialize(obj, prefix) {
  const str = [];
  Object.entries(obj).forEach(([k, v]) => {
    const prefixedK = prefix ? `${prefix}[${k}]` : k;

    if (v !== null && typeof v === 'object') {
      str.push(serialize(v, prefixedK));
    } else if (v !== null) {
      str.push(`${encodeURIComponent(prefixedK)}=${encodeURIComponent(v)}`);
    }
  });

  return str.join('&');
}

export function clearTimeouts(timeoutIds) {
  let i = 0;
  while (i < timeoutIds.length) {
    clearTimeout(timeoutIds[i]);
    i += 1;
  }
}

export function updateUrl(urlString) {
  let url = urlString;
  if (typeof urlString === 'string') {
    url = new URL(url);
  }

  if (url.pathname.split('.')[0] !== window.location.pathname) {
    return;
  }

  if (window.history.pushState) {
    window.history.pushState({ path: url.toString() }, '', url.toString());
  }
}
window.updateUrl = updateUrl;

export function escapeHtml(htmlString) {
  const replacements = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    "'": '&#039;',
    '"': '&quot;'
  };
  return htmlString.toString().replace(/[&<>"']/g, (match) => replacements[match]);
}

export function woots() {
  return window.location.host.includes('woots');
}

export function resetHotkeys(context) {
  if (context.previousHotkeysDisabled !== undefined) {
    document.body.dataset.hotkeysDisabled = context.previousHotkeysDisabled;
  } else {
    delete document.body.dataset.hotkeysDisabled;
  }
}
