import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';
import Fuse from 'fuse.js';

export default class extends Controller {
  static debounces = ['search'];

  static targets = ['clear', 'query', 'submit', 'result'];

  connect() {
    useDebounce(this, { wait: 300 });
    if (this.hasClearTarget) { this.toggleClearButtonVisibility(); }
  }

  focus() {
    if (this.hasQueryTarget) { this.queryTarget.focus(); }
  }

  search() {
    this.toggleClearButtonVisibility();
    if (this.hasSubmitTarget) { this.submitTarget.click(); }
    if (this.hasResultTarget) { this.showResults(); }
  }

  clear() {
    this.queryTarget.value = '';
    this.clearTarget.classList.add('d-none');

    if (this.hasSubmitTarget) { this.submitTarget.click(); }
    if (this.hasResultTarget) { this.showAllResults(); }
    this.queryTarget.focus();
  }

  toggleClearButtonVisibility() {
    if (!this.hasClearTarget) return;

    if (this.queryTarget.value.length > 0) {
      this.clearTarget.classList.remove('d-none');
    } else {
      this.clearTarget.classList.add('d-none');
    }
  }

  showAllResults() {
    this.resultTargets.forEach((result) => { result.classList.remove('hidden'); });
  }

  showResults() {
    if (this.queryTarget.value.length === 0) { return this.showAllResults(); }

    const matches = this.findMatches();

    this.resultTargets.forEach((result) => {
      const { value } = result.dataset;
      if (matches.includes(value)) {
        result.classList.remove('hidden');
      } else {
        result.classList.add('hidden');
      }
    });
  }

  findMatches() {
    const array = [];
    this.resultTargets.forEach((result) => {
      const { value } = result.dataset;
      array.push({ key: value });
    });

    const options = {
      isCaseSensitive: false, threshold: 0.1, minMatchCharLength: 1, ignoreLocation: true, keys: ['key']
    };
    const search = { fuse: new Fuse(array, options) };
    return search.fuse.search(this.queryTarget.value).map((x) => x.item.key);
  }
}
