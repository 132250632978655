/* global i18n */

import Rails from '@rails/ujs';
import snackbar from '@components/snackbar';

export const loadCommentList = function (url) {
  Rails.ajax({
    url,
    dataType: 'script',
    type: 'GET',
    success: () => {
      if (document.querySelector('[data-js-highlight-comment-panel] [data-js-highlight]')) {
        document.querySelector('[data-js-highlight-comment-panel-empty-state]').classList.add('d-none');
      } else {
        document.querySelector('[data-js-highlight-comment-panel-empty-state]').classList.remove('d-none');
      }
    }
  });
};

export const focusHighlight = function (highlightId) {
  const highlight = document.querySelector(`.highlight-identifier-${highlightId}`);
  if (highlight) {
    highlight.click();
  }
};

export const blurHighlight = function () {
  const event = new Event('blur');
  const popTip = document.querySelector('.deprecated-highlight-poptip');
  if (popTip) {
    popTip.dispatchEvent(event);
  }
};

export const DeprecatedHighlightWorker = function (element, options) {
  const self = this;
  this.element = element;
  this.settings = {
    highlightableUrl: options.highlightableUrl || '',
    highlightableId: options.highlightableId || '',
    readOnly: options.readOnly || false,
    disabled: options.disabled || false,
    nodeIdentifierKey: options.nodeIdentifierKey || 'chnode',
    highlightClass: options.highlightClass || 'highlight',
    highlightIdentifyClassRoot: options.highlightIdentifyClassRoot || 'highlight-identifier-',
    highlightActiveClass: options.highlightActiveClass || 'highlight-active',
    popTipClass: options.popTipClass || 'deprecated-highlight-poptip',
    popTipDefaultHead: options.popTipDefaultHead || 'Highlight'
  };

  async function getRangy() {
    const [rangy] = await Promise.all([
      import('rangy/lib/rangy-core'),
      import('rangy/lib/rangy-classapplier')
    ]);
    return rangy.default;
  }

  this.init = function () {
    getRangy().then((rangy) => {
      self.rangy = rangy;

      const highlightables = document.querySelector('[data-js-responses-with-highlights]');
      const highlightableIds = JSON.parse(highlightables.dataset.jsResponsesWithHighlights);
      const highlightableId = parseInt(self.element.dataset.highlightableId, 10);

      if (highlightableIds.includes(highlightableId)) {
        self.getHighlights();
      }

      if (!self.settings.readOnly) {
        element.addEventListener('pointerup', self.handleMouseUp);
      }
    }).catch((error) => console.log(error)); // eslint-disable-line no-console
  };

  this.destroy = function () {
    element.removeEventListener('mouseup', this.handleMouseUp);
  };

  this.getHighlights = function () {
    Rails.ajax({
      url: self.settings.highlightableUrl,
      dataType: 'json',
      type: 'GET',
      error: () => {
        console.error('Failed to get highlights'); // eslint-disable-line no-console
      },
      success: (data) => {
        if (!self.rangy.initialized) self.rangy.init();

        self.modifyHighlights(data, 'add');
      }
    });
  };

  this.getComments = function (highlightId) {
    Rails.ajax({
      url: `/highlights/${highlightId}/highlight_comments`,
      type: 'GET',
      success: () => {
        document.querySelector('[data-js-highlight-comment-panel-empty-state]').classList.add('d-none');
      }
    });
  };

  this.handleMouseUp = function () {
    let bookmarkObject;
    let commonAncestor;
    let range;
    let position;
    const selection = self.rangy.getSelection();

    if (selection.toHtml().includes('highlight-identifier')) {
      snackbar(i18n.t('js.highlights.cannot_overlap'), true);
      return;
    }

    if (selection.isCollapsed === false &&
        selection.rangeCount > 0 &&
        selection.toString().trim().length) {
      range = selection.getRangeAt(0);
      commonAncestor = range.commonAncestorContainer;

      while (commonAncestor.dataset === undefined ||
             commonAncestor.dataset[self.settings.nodeIdentifierKey] === undefined) {
        commonAncestor = commonAncestor.parentNode;
        if (commonAncestor === undefined || commonAncestor.contains(this)) {
          return;
        }
        if (commonAncestor.dataset.highlightId !== undefined) {
          snackbar(i18n.t('js.highlights.cannot_overlap'), true);
          return;
        }
      }

      let rootBookmarkObject;
      try {
        rootBookmarkObject = selection.getBookmark(this);
      } catch (error) {
        return;
      }

      if (rootBookmarkObject && rootBookmarkObject.rangeBookmarks.length > 0) {
        position = rootBookmarkObject.rangeBookmarks[0].start;
      }

      bookmarkObject = selection.getBookmark(commonAncestor);
      if (bookmarkObject && bookmarkObject.rangeBookmarks.length > 0) {
        self.createHighlight({
          container_node_type: commonAncestor.tagName,
          container_node_identifier_key: self.settings.nodeIdentifierKey,
          container_node_identifier: commonAncestor.dataset[self.settings.nodeIdentifierKey],
          startnode_offset: bookmarkObject.rangeBookmarks[0].start,
          endnode_offset: bookmarkObject.rangeBookmarks[0].end,
          selection_backward: bookmarkObject.backward,
          content: selection.toString(),
          position: position || bookmarkObject.rangeBookmarks[0].start
        });
      } else {
        return;
      }
      selection.detach();
    }
  };

  this.createHighlight = function (highlightParams) {
    const token = document.head.querySelector("[name='csrf-token']").content;
    const params = { highlight: highlightParams };

    fetch(self.settings.highlightableUrl, {
      method: 'POST',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify(params)
    }).then((response) => {
      if (!response.ok) console.error('Failed to create highlight'); // eslint-disable-line no-console
      return response.json();
    }).then((data) => {
      self.modifyHighlights([data], 'add');

      const highlight = this.element.querySelector(`.highlight-identifier-${data.identifier}`);
      self.showPopTipFor(highlight);
    });
  };

  this.destroyHighlight = (highlightElement) => {
    const { highlightId } = highlightElement.dataset;

    Rails.ajax({
      url: `/highlights/${highlightId}`,
      type: 'DELETE',
      error: () => {
        console.error('Failed to remove highlight'); // eslint-disable-line no-console
      },
      success: (data) => {
        self.modifyHighlights(data, 'destroy');
        loadCommentList(self.settings.highlightableUrl);
      }
    });
  };

  this.setEventListenersToHighlights = (highlights) => {
    for (let iter = 0; iter < highlights.length; iter += 1) {
      highlights[iter].addEventListener('click', self.handleHighlightClick);
    }
  };

  this.removeEventListenersFromHighlights = (highlights) => {
    for (let iter = 0; iter < highlights.length; iter += 1) {
      highlights[iter].removeEventListener('click', self.handleHighlightClick);
    }
  };

  this.handleHighlightClick = function (e) {
    if (self.settings.disabled) {
      return;
    }

    e.stopPropagation();
    self.showPopTipFor(this);
  };

  this.modifyHighlights = function (highlights, modifyAction) {
    const selection = self.rangy.getSelection();
    let classApplier;
    let elementSet;
    for (let iter = 0; iter < highlights.length; iter += 1) {
      const highlightableObject = highlights[iter];
      const className = self.settings.highlightIdentifyClassRoot + highlightableObject.identifier;
      const containerNode = element.querySelector(`${highlightableObject.common_ancestor_node_type}[data-${self.settings.nodeIdentifierKey}='${highlightableObject.common_ancestor_identifier}']`); // eslint-disable-line max-len
      if (containerNode !== undefined) {
        const bookmarkObject = {
          backward: highlightableObject.backward,
          rangeBookmarks: [{
            start: highlightableObject.start_offset,
            end: highlightableObject.end_offset,
            containerNode
          }]
        };
        classApplier = self.rangy.createClassApplier(self.settings.highlightIdentifyClassRoot + highlightableObject.identifier, { // eslint-disable-line max-len
          elementProperties: {
            className: self.settings.highlightClass
          }
        });
        selection.moveToBookmark(bookmarkObject);
        if (selection.toString() === highlightableObject.content) {
          switch (modifyAction) {
            case 'add':
              classApplier.applyToSelection();
              elementSet = element.getElementsByClassName(className);
              self.addDataAttributesToElements(elementSet, [
                ['highlightId', highlightableObject.identifier],
                ['removable', highlightableObject.can_edit],
                ['comments', highlightableObject.comments_count]
              ]);

              self.setEventListenersToHighlights(elementSet);
              break;
            case 'destroy':
              elementSet = element.getElementsByClassName(className);
              self.removeDataAttributesFromElements(elementSet, ['highlightId', 'removable', 'comments']);
              self.removeEventListenersFromHighlights(elementSet);
              self.unmarkActiveHighlight();
              classApplier.undoToSelection();

              break;
            default:
              break;
          }
        }
      }
    }
    selection.removeAllRanges();
    selection.detach();
  };

  this.addDataAttributesToElements = (anyElements, dataAttributesArray) => {
    if (dataAttributesArray.length > 0) {
      for (let iter = 0; iter < anyElements.length; iter += 1) {
        for (let iter1 = 0; iter1 < dataAttributesArray.length; iter1 += 1) {
          if (anyElements[iter].dataset) {
            anyElements[iter].dataset[dataAttributesArray[iter1][0]] = dataAttributesArray[iter1][1]; // eslint-disable-line prefer-destructuring
          }
        }
      }
    }
  };

  this.removeDataAttributesFromElements = (anyElements, dataAttributesArray) => {
    if (dataAttributesArray.length > 0) {
      for (let iter = 0; iter < anyElements.length; iter += 1) {
        for (let iter1 = 0; iter1 < dataAttributesArray.length; iter1 += 1) {
          if (anyElements[iter].dataset) {
            delete anyElements[iter].dataset[dataAttributesArray[iter1]];
          }
        }
      }
    }
  };

  this.markHighlightAsActive = (anyElement) => {
    const className = self.settings.highlightIdentifyClassRoot + anyElement.dataset.highlightId;
    const anyElements = self.element.getElementsByClassName(className);
    for (let iter = 0; iter < anyElements.length; iter += 1) {
      anyElements[iter].classList.add(self.settings.highlightActiveClass);
    }
  };

  this.unmarkActiveHighlight = () => {
    const activeHighlights = self.element.getElementsByClassName(self.settings.highlightActiveClass);
    while (activeHighlights[0]) {
      activeHighlights[0].classList.remove(self.settings.highlightActiveClass);
    }
  };

  this.showPopTipFor = (highlightElement) => {
    const { highlightId } = highlightElement.dataset;
    self.removePopTip();

    self.buildPopupFromHighlightElement(highlightElement);
    if (self.popTip !== undefined) {
      self.activeHighlightId = highlightId;
      self.positionPopTip(null, highlightElement);
      self.popTip.dataset.highlightId = highlightId;
      self.element.appendChild(self.popTip);
      self.markHighlightAsActive(highlightElement);

      window.addEventListener('resize', self.removePopTip);
      window.addEventListener('scroll', self.positionPopTip, true);
      document.addEventListener('mousedown', self.removePopTip);
      self.popTip.addEventListener('blur', self.removePopTip);
    }

    highlightElement.classList.add(self.settings.highlightActiveClass);
    self.getComments(highlightId);
  };

  this.positionPopTip = () => {
    const highlightElements = document.querySelectorAll(`.highlight-identifier-${self.activeHighlightId}`);
    let rect = null;

    for (let iter = 0; iter < highlightElements.length; iter += 1) {
      const rects = highlightElements[iter].getClientRects();
      if (!rect) {
        [rect] = rects;
      }

      for (let iter2 = 0; iter2 < rects.length; iter2 += 1) {
        const tempRect = rects[iter2];

        if (tempRect.top >= rect.top && tempRect.left >= rect.left) {
          rect = tempRect;
        }
      }
    }
    self.popTip.style.top = `${rect.top - 15}px`;
    self.popTip.style.left = `${rect.left + rect.width}px`;
  };

  this.removePopTip = (e) => {
    if (e && e.type !== 'blur' &&
             e.type !== 'resize' &&
             (e.target.closest('[data-js-highlight-comment-panel]') ||
              e.target.closest('.deprecated-highlight-poptip') ||
              e.target.closest('[data-js-confirm-dialog]') ||
              e.target.closest('.redactor-modal, .redactor-dropdown') ||
              e.target.closest('#redactor-image-resizer, #redactor-shortcut-autocomplete-list') ||
              e.target.closest('#redactor-handle-list'))
    ) {
      return;
    }

    if (self.popTip !== undefined) {
      self.element.removeChild(self.popTip);
      self.popTip = undefined;
      self.unmarkActiveHighlight();
      window.removeEventListener('resize', self.removePopTip);
      window.removeEventListener('scroll', self.positionPopTip, true);
      document.removeEventListener('mousedown', self.removePopTip);
    }

    if (e) {
      loadCommentList(self.settings.highlightableUrl);
    }
  };

  this.buildPopupFromHighlightElement = (highlightElement) => {
    self.popTip = document.createElement('div');
    self.popTip.className = self.settings.popTipClass;

    if (highlightElement.dataset.removable === 'true') {
      self.popTip.innerHTML += "<a href='#!' class='cancel_highlight'><i class='material-icons tiny'>close</i></a>";
      if (self.popTip.querySelector('.cancel_highlight') !== undefined) {
        self.popTip.querySelector('.cancel_highlight').addEventListener('mousedown', () => {
          const hasComments = parseInt(highlightElement.dataset.comments, 10) > 0;
          if (hasComments && !window.confirm(i18n.t('js.highlights.confirm_destroy'))) { // eslint-disable-line no-alert
            return;
          }
          self.removePopTip();
          self.destroyHighlight(highlightElement);
        });
      }
    } else {
      self.popTip.innerHTML += "<i class='material-icons tiny blur-highlight'>close</i>";
      if (self.popTip.querySelector('.blur-highlight') !== undefined) {
        self.popTip.querySelector('.blur-highlight').addEventListener('mousedown', () => {
          blurHighlight();
        });
      }
    }
  };
};
