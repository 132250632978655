/* global i18n, Turbo */

import snackbar from '@components/snackbar';
import { debounce } from '@modules/custom';

export const focusHighlight = function (highlightId) {
  const highlight = document.querySelector(`.highlight-identifier-${highlightId}`);
  if (highlight) {
    highlight.click();
  }
};

export const HighlightWorker = function (element, options) {
  const self = this;
  this.element = element;
  this.settings = {
    highlightableUrl: options.highlightableUrl || '',
    highlightableId: options.highlightableId || '',
    readOnly: options.readOnly || false,
    disabled: options.disabled || false,
    nodeIdentifierKey: options.nodeIdentifierKey || 'chnode',
    highlightIdentifyClassRoot: options.highlightIdentifyClassRoot || 'highlight-identifier-',
    highlightActiveClass: options.highlightActiveClass || 'highlight-active',
    popTipClass: options.popTipClass,
    currentUserId: options.currentUserId
  };

  async function getRangy() {
    const [rangy] = await Promise.all([
      import('rangy/lib/rangy-core'),
      import('rangy/lib/rangy-classapplier')
    ]);
    return rangy.default;
  }

  this.init = function () {
    getRangy().then((rangy) => {
      self.rangy = rangy;

      self.getHighlights();

      if (!self.settings.readOnly) {
        element.addEventListener('pointerup', self.handleMouseUp);
      }
    }).catch((error) => console.log(error)); // eslint-disable-line no-console
  };

  this.destroy = function () {
    element.removeEventListener('mouseup', this.handleMouseUp);
  };

  this.getHighlights = function () {
    Turbo.fetch(self.settings.highlightableUrl, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to get highlights');
        }
        return response.json();
      })
      .then((data) => {
        if (!self.rangy.initialized) self.rangy.init();

        self.modifyHighlights(data);
      })
      .catch((error) => {
        console.error('Failed to get highlights', error); // eslint-disable-line no-console
      });
  };

  this.handleMouseUp = debounce(function () {
    let bookmarkObject;
    let commonAncestor;
    let range;
    let position;
    const selection = self.rangy.getSelection();

    if (selection.toHtml().includes('highlight-identifier')) {
      snackbar(i18n.t('js.highlights.cannot_overlap'), true);
      return;
    }

    if (selection.isCollapsed === false &&
        selection.rangeCount > 0 &&
        selection.toString().trim().length) {
      range = selection.getRangeAt(0);
      commonAncestor = range.commonAncestorContainer;

      while (commonAncestor.dataset === undefined ||
             commonAncestor.dataset[self.settings.nodeIdentifierKey] === undefined) {
        commonAncestor = commonAncestor.parentNode;
        if (commonAncestor === undefined || commonAncestor.contains(this)) {
          return;
        }
        if (commonAncestor.dataset.highlightId !== undefined) {
          snackbar(i18n.t('js.highlights.cannot_overlap'), true);
          return;
        }
      }

      let rootBookmarkObject;
      try {
        rootBookmarkObject = selection.getBookmark(this);
      } catch (error) {
        return;
      }

      if (rootBookmarkObject && rootBookmarkObject.rangeBookmarks.length > 0) {
        position = rootBookmarkObject.rangeBookmarks[0].start;
      }

      bookmarkObject = selection.getBookmark(commonAncestor);

      if (bookmarkObject && bookmarkObject.rangeBookmarks.length > 0) {
        self.createHighlight({
          container_node_type: commonAncestor.tagName,
          container_node_identifier_key: self.settings.nodeIdentifierKey,
          container_node_identifier: commonAncestor.dataset[self.settings.nodeIdentifierKey],
          startnode_offset: bookmarkObject.rangeBookmarks[0].start,
          endnode_offset: bookmarkObject.rangeBookmarks[0].end,
          selection_backward: bookmarkObject.backward,
          content: selection.toString(),
          position: position || bookmarkObject.rangeBookmarks[0].start
        });
      } else {
        return;
      }
      selection.detach();
    }
  }, 250);

  this.createHighlight = function (highlightParams) {
    const token = document.head.querySelector("[name='csrf-token']").content;
    const params = { highlight: highlightParams };

    Turbo.fetch(self.settings.highlightableUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token,
        accept: 'text/vnd.turbo-stream.html,'
      },
      body: JSON.stringify(params)
    })
      .then((res) => res.text())
      .then((html) => Turbo.renderStreamMessage(html));
  };

  this.modifyHighlights = function (highlights) {
    const selection = self.rangy.getSelection();
    let classApplier;
    let elementSet;
    for (let iter = 0; iter < highlights.length; iter += 1) {
      const highlightableObject = highlights[iter];
      const className = self.settings.highlightIdentifyClassRoot + highlightableObject.identifier;
      const containerNode = element.querySelector(`${highlightableObject.common_ancestor_node_type}[data-${self.settings.nodeIdentifierKey}='${highlightableObject.common_ancestor_identifier}']`); // eslint-disable-line max-len
      const clickAction = Number(this.settings.currentUserId) === highlightableObject.user_id ? 'click->highlights#openHighlightToolTip' : ''; // eslint-disable-line max-len
      if (containerNode !== undefined) {
        const bookmarkObject = {
          backward: highlightableObject.backward,
          rangeBookmarks: [{
            start: highlightableObject.start_offset,
            end: highlightableObject.end_offset,
            containerNode
          }]
        };
        classApplier = self.rangy.createClassApplier(self.settings.highlightIdentifyClassRoot + highlightableObject.identifier, { // eslint-disable-line max-len
          elementProperties: {
            className: `highlight-color-${highlightableObject.color}` // eslint-disable-line max-len
          }
        });
        selection.moveToBookmark(bookmarkObject);
        if (selection.toString() === highlightableObject.content) {
          classApplier.applyToSelection();
          elementSet = element.getElementsByClassName(className);
          self.addDataAttributesToElements(elementSet, [
            ['highlightId', highlightableObject.identifier],
            ['removable', highlightableObject.can_edit],
            ['commentsCount', highlightableObject.comments_count],
            ['highlightsTarget', 'highlight'],
            ['action', clickAction],
            ['highlightsIdParam', highlightableObject.identifier],
            ['color', highlightableObject.color]
          ]);
        }
      }
    }
    selection.removeAllRanges();
    selection.detach();
  };

  this.addDataAttributesToElements = (anyElements, dataAttributesArray) => {
    if (dataAttributesArray.length > 0) {
      for (let iter = 0; iter < anyElements.length; iter += 1) {
        for (let iter1 = 0; iter1 < dataAttributesArray.length; iter1 += 1) {
          if (anyElements[iter].dataset) {
            anyElements[iter].dataset[dataAttributesArray[iter1][0]] = dataAttributesArray[iter1][1]; // eslint-disable-line prefer-destructuring
          }
        }
      }
    }
  };
};
