import { Controller } from '@hotwired/stimulus';
import { useHover, useDebounce } from 'stimulus-use';
import { createPopper } from '@popperjs/core';

function findTooltipContent(hoverElement) {
  let content = null;
  const tooltipTextEl = hoverElement.querySelector('.tooltip-text');

  if (tooltipTextEl) {
    content = tooltipTextEl.innerHTML;
  } else if (hoverElement.ariaLabel) {
    content = hoverElement.ariaLabel;
  }

  return content;
}

function createTemporaryTooltip(hoverElement) {
  const tooltipContent = findTooltipContent(hoverElement);
  if (!tooltipContent) return null;

  const tooltipClass = `tooltip-text ${hoverElement.dataset.wideTooltip ? 'tooltip-text--wide' : ''}`;
  const temporaryTooltipEl = `
    <div class="tooltip" data-temporary-tooltip data-cy="tooltip">
      <span class="${tooltipClass}" style="display:block">${tooltipContent}</span>
      <div class="arrow" data-popper-arrow></div>
    </div>
  `;

  hoverElement.insertAdjacentHTML('beforebegin', temporaryTooltipEl);

  return document.querySelector('[data-temporary-tooltip]');
}

function positionTooltipWithinScreen(hoverElement, temporaryTooltipEl) {
  const { tooltipPlacement } = hoverElement.dataset;
  const tooltipCaret = temporaryTooltipEl.querySelector('[data-popper-arrow]');

  createPopper(hoverElement, temporaryTooltipEl, {
    placement: (tooltipPlacement || 'top'),
    modifiers: [{
      name: 'preventOverflow',
      options: {
        altAxis: true,
        padding: 8
      }
    },
    {
      name: 'offset',
      options: {
        offset: ({ placement }) => {
          if (placement === 'left' || placement === 'right') return [0, 4];

          return [0, 0];
        }
      }
    },
    {
      name: 'arrow',
      options: {
        element: tooltipCaret,
        padding: [10, 0]
      }
    }]
  });
}

function removeTemporaryTooltips() {
  document.querySelectorAll('[data-temporary-tooltip]').forEach((tooltip) => tooltip.remove());
}

export default class extends Controller {
  static debounces = ['mouseEnter'];

  static targets = ['disabled'];

  connect() {
    useDebounce(this, { wait: 100 });
    useHover(this, { element: this.element });
  }

  mouseEnter() {
    if (this.hasDisabledTarget) return;

    if (this.element.matches(':hover')) {
      const temporaryTooltipEl = createTemporaryTooltip(this.element);
      if (temporaryTooltipEl) positionTooltipWithinScreen(this.element, temporaryTooltipEl);
    }
  }

  disconnect() {
    removeTemporaryTooltips();
  }

  mouseLeave() {
    removeTemporaryTooltips();
  }
}
